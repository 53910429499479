import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';
import Link from '../components/utils/link';
import * as styles from '../modules/nutritionnistes.module.scss';
import HeaderPage from '../components/repeatable/headerPage';
import DietList from '../components/static/nutritionist'

function IndexPage({ data }) {
  const {
    pageNutritionnistesYaml: { title, seo, description },
  } = data;

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />

      {/* Header */}
      <div className={styles.baseStyles}>
        <HeaderPage title={title} description={description} />
      </div>

      <DietList />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageNutritionnistesYaml {
      title
      description

      seo {
        title
        description
        canonical
      }
    }
  }
`;
